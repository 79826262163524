import React, { useContext } from 'react';

// import components
import CountryDropdown from './CountryDropdown';
import PropertyDropdown from './PropertyDropdown';

// import context
import { HouseContext } from './HouseContext';

// import icon
import CategoryDropdown from './CategoryDropdown';

const Search = () => {
  const { handleClick,resetFilters } = useContext(HouseContext);
  return (
    <div className='px-[30px] py-6 max-w-[1370px] mx-auto flex flex-col sm:flex-row justify-between gap-4 lg:gap-x-3 relative lg:-top-4 lg:shadow-1 bg-white lg:bg-transparent lg:backdrop-blur rounded-lg'>
      <CountryDropdown />
      <PropertyDropdown />
      {/* <PriceRangeDropdown /> */}
      <CategoryDropdown/>
      <button
  onClick={() => {handleClick()}}
  className="bg-customviolet hover:bg-customviolet_light transition text-white text-base sm:text-lg px-4 py-2 rounded-lg flex justify-center items-center w-full sm:max-w-xs md:max-w-sm lg:max-w-md"
>
  <span className="hidden sm:inline-block mr-2">Search</span>
  <svg
    className="w-6 h-6"
    fill="none"
    stroke="currentColor"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
    />
  </svg>
</button>
      {/* <button
        onClick={() => {
          handleClick();
        }}
        className='bg-violet-700 hover:bg-violet-800 transition w-full lg:max-w-[162px] h-16 rounded-lg flex justify-center items-center text-white text-lg'
      >
        <RiSearch2Line />
      </button> */}
    </div>
  );
};

export default Search;
