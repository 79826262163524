// Header.js
import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Logo from '../assets/img/logo.jpeg';

const Header = () => {
 const [isOpen, setIsOpen] = useState(false);
 const location = useLocation();
 const navigate = useNavigate();
 const [shouldScroll, setShouldScroll] = useState(false);

 const toggleMenu = () => {
    setIsOpen(!isOpen);
 };

 useEffect(() => {
    if (shouldScroll && location.pathname === "/") {
      document.getElementById('top-properties').scrollIntoView({ behavior: 'smooth' });
      setShouldScroll(false); // Reset the flag after scrolling
    }
 }, [location, shouldScroll]);

 const handleClick = () => {
    if (location.pathname !== "/") {
      navigate("/");
      setShouldScroll(true); // Set the flag to scroll after navigation
    } else {
      document.getElementById('top-properties').scrollIntoView({ behavior: 'smooth' });
    }
 };

 return (
    <>
      <div className="relative whitespace-nowrap flex overflow-x-hidden bg-customviolet">
        <marquee className="text-white text-base font-italic" behavior="scroll" direction="left" scrollamount="3">
        Prime possessionable 80 Sq yards commercial SCO plot for sale on Airport Road, SAS Nagar, Mohali - seize this golden opportunity! For more details contant Rajpal Realtors at +91 7460999298
        </marquee>
      </div>

      <header className="py-6">
        <div className="container mx-auto flex justify-between items-center">
          <Link to="/">
            <img src={Logo} alt="Logo" className="h-12 w-auto sm:h-16" />
          </Link>
          <div className="hidden sm:flex items-center gap-6">
            {/* Desktop view buttons */}
            <button
              onClick={() => {
                handleClick();
              }}
              className="bg-customviolet hover:bg-customviolet_light text-white px-4 py-3 rounded-lg transition"
            >
              Properties
            </button>
            <button
              onClick={() => document.getElementById('footer').scrollIntoView({ behavior: 'smooth' })}
              className="bg-customviolet hover:bg-customviolet_light text-white px-4 py-3 rounded-lg transition"
            >
              Contact Us
            </button>
            <button
               onClick={() => window.location.href = "/aboutus"}
              className="bg-customviolet hover:bg-customviolet_light text-white px-4 py-3 rounded-lg transition"
            >
              About Us
            </button>
          </div>
          {/* Mobile view menu button */}
          <button
            onClick={toggleMenu}
            className="sm:hidden bg-customviolet hover:bg-customviolet_light text-white px-4 py-3 rounded-lg transition"
          >
            Menu
          </button>
        </div>
        {/* Mobile view menu */}
        {isOpen && (
          <div className="sm:hidden bg-customviolet text-white p-4">
            <button
              onClick={() => document.getElementById('top-properties').scrollIntoView({ behavior: 'smooth' })}
              className="w-full text-center mb-4"
            >
              Properties
            </button>
            <button
              onClick={() => document.getElementById('footer').scrollIntoView({ behavior: 'smooth' })}
              className="w-full text-center mb-4"
            >
              Contact Us
            </button>
            <button
              onClick={() => window.location.href = "/aboutus"}
              className="w-full text-center"
            >
              About Us
            </button>
          </div>
        )}
      </header>
    </>
 );
};

export default Header;
