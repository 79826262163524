import React from 'react';

import Image from '../assets/img/house-banner.png';
import Search from '../components/Search';

const Banner = () => {
  return (
    <section className='h-full max-h-[640px] mb-8 xl:mb-24'>
      <div className='flex flex-col lg:flex-row'>
        <div className='lg:ml-8 xl:ml-[70px] flex flex-col items-center lg:items-start text-center lg:text-left justify-center flex-1 px-4 lg:px-0'>
          <h1 className='text-4xl lg:text-[58px] font-semibold leading-none mb-6'>
            <span className='text-customviolet'>Buy and Sell</span> Your<br />Real Estate Assets With Us
          </h1>
          <p className='max-w-[550px] mb-8 text-lg'>
            Discover the true potential of your properties with Rajpal Realtors.
          </p>
          <div className='hidden lg:flex justify-center space-x-2 mt-4 '>
            <Search />

            <div style={{ maxWidth: '90%' }}></div>
          </div>
        </div>
        <div className='flex-1 lg:flex justify-end items-end'>
          <img src={Image} alt='' className='max-w-full lg:max-w-[90%]' />
        
        </div>
        </div>
    </section>
  );
};

export default Banner;
