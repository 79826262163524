import React from 'react';

// import icons
import { BiBed, BiBath, BiArea } from 'react-icons/bi';
import '../components/house.css'
const House = ({ house }) => {
  return (
    <div className='bg-white shadow-1 p-5 rounded-lg  w-full max-w-[352px] mx-auto cursor-pointer hover:shadow-2xl transition'>
      {/* <img className='mb-8' src={house.image} alt='house' /> */}
      <img src={house.img} className="card-img-top" alt="..." />
      <div className='mt-3'></div>
      {/* <img className='mb-8' src={house.img} alt={house.name} /> */}
      <div className='scrollbar-hide mb-4 flex gap-x-2 text-sm overflow-x-auto chips-container'>
 <div className='relative grid select-none items-center whitespace-nowrap rounded-lg bg-green-500 py-1.5 px-3 font-sans text-xs font-bold uppercase text-white'>
    <span>{house.type}</span>
 </div>
 <div className='relative grid select-none items-center whitespace-nowrap rounded-lg bg-customviolet py-1.5 px-3 font-sans text-xs font-bold uppercase text-white'>
    <span>{house.country}</span>
 </div>
 <div className='relative grid select-none items-center whitespace-nowrap rounded-lg bg-green-500 py-1.5 px-3 font-sans text-xs font-bold uppercase text-white'>
    <span>{house.category}</span>
 </div>
 <div className='relative grid select-none items-center whitespace-nowrap rounded-lg bg-customviolet py-1.5 px-3 font-sans text-xs font-bold uppercase text-white'>
    <span>{house.status}</span>
 </div>
</div>
      <div className='text-lg font-semibold max-w-[260px]'>{house.name}</div>
      <div className='flex gap-x-4 my-4'>
        {house.bedrooms !== '' && (
          <div className='flex items-center text-gray-600 gap-1'>
            <div className='text-[20px] rounded-full'>
              <BiBed />
            </div>
            <div className='text-base'>{house.bedrooms}</div>
          </div>
        )}
        {house.bathrooms !== '' && (
          <div className='flex items-center text-gray-600 gap-1'>
            <div className='text-[20px] rounded-full'>
              <BiBed />
            </div>
            <div className='text-base'>{house.bathrooms}</div>
          </div>
        )}
        <div className='flex items-center text-gray-600 gap-1'>
          <div className='text-[20px] rounded-full'>
            <BiArea />
          </div>
          <div className='text-base'>{house.surface}</div>
        </div>
      </div>
      {/* <div className='text-lg font-semibold text-customviolet mb-4'>
        ₹ {house.price}
      </div> */}
    </div>

  );
};

export default House;
