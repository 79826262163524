import React, { useState } from 'react';
import HouseList from '../components/HouseList';
import Banner from '../components/Banner';
import { Link } from 'react-router-dom';
import Search from '../components/Search';

const Home = () => {
  const [showSearch, setShowSearch] = useState(false);

  const toggleSearch = () => {
      setShowSearch(!showSearch);
  };

  return (
    <div className=''>

      <Banner />
    <div className="mb-10 md:mb-20 lg:mb-32"></div>
    <h1 id="top-properties" className="text-center text-4xl lg:text-[44px] font-semibold mb-10">
      <span className='text-customviolet'>Our </span>Top
      <span className='text-customviolet'> Properties</span>
    </h1>

    <div className="flex justify-center items-center  mb-10 lg:hidden">
        <button onClick={toggleSearch} className="bg-customviolet text-white px-4 py-2 rounded ">
           Explore
        </button>
      </div>
    {showSearch && (
      <div className='flex justify-center space-x-2 mt-4 lg:hidden'>
        <Search />
      </div>
    )}
    <HouseList />
    <Link to='/allproperty' className="block">
      <h1 className='text-customviolet hover:text-customviolet_light text-center text-2xl sm:text-3xl lg:text-[20px] font-semibold mb-16'>
        View All
      </h1>
    </Link>
    </div>
  );
};

export default Home;
