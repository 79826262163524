import React from 'react';
import Logo from '../assets/img/3D.jpg';
const AboutUs = () => {
    return (
        <>
           <section class="bg-white">
    <div class="container mx-auto py-16 px-4 sm:px-6 lg:px-8">
        <div class="grid grid-cols-1 md:grid-cols-2 items-center gap-8">
            <div class="max-w-lg">
                <h2 class="text-3xl font-extrabold text-gray-900 sm:text-4xl">About Us</h2>
                <p class="mt-4 text-gray-600 text-lg">
                    Welcome to Rajpal Realtor, where your dreams of finding
                    the perfect home come to life. With years of experience and
                    a dedicated team of professionals, we pride ourselves on
                    providing top-notch service to our clients. Whether you're
                    buying, selling, or renting, we're here to guide you every
                    step of the way.
                </p>
                <p class="mt-4 text-gray-600 text-lg">
                    At Rajpal Realtor, we understand that finding the right
                    property can be a daunting task. That's why we offer
                    personalized assistance tailored to your unique needs and
                    preferences. From luxurious estates to cozy apartments, we
                    have a diverse portfolio to suit every lifestyle and budget.
                </p>
                <p class="mt-4 text-gray-600 text-lg">
                    Our commitment to excellence doesn't stop at the
                    transaction. We believe in building long-lasting
                    relationships with our clients, ensuring their
                    satisfaction for years to come. With integrity, transparency,
                    and professionalism at the core of everything we do, you
                    can trust Rajpal Realtor to make your real estate journey a
                    seamless and rewarding experience.
                </p>
                <p class="mt-4 text-gray-600 text-lg">
                    Discover your dream home with Rajpal Realtor today. Welcome
                    home.
                </p>
              
            </div>
            <div class="mt-12 md:mt-0">
                <img src={Logo} alt="About Us Image" class="object-cover rounded-lg shadow-md"/>
            </div>
        </div>
    </div>
</section>
        </>
    );
};

export default AboutUs;
