import React, { useState, useContext } from "react";
// import icons
import {RiHome5Line, RiArrowDownSLine, RiArrowUpSLine } from "react-icons/ri";
// import headless ui components
import { Menu } from "@headlessui/react";
// import context
import { HouseContext } from "./HouseContext";
import { useLocation } from 'react-router-dom';

const CategoryDropdown = () => {
  const {category, setCategory, categories,dropdownOptions} = useContext(HouseContext);
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();


  const displayText = category ? category : "Category";

  return (
    <Menu as="div" className="dropdown relative">
      <Menu.Button
        onClick={() => setIsOpen(!isOpen)}
        className="dropdown-btn w-full text-left"
      >
        <RiHome5Line className="dropdown-icon-primary text-customviolet" />
        <div className=""> {/* Adjust the width as needed */}
        {location.pathname==="/allproperty" ? <div className="text-[19px]  font-medium leading-tight" style={{ overflow: 'hidden', textOverflow: 'ellipsis',  }}>{displayText}</div> : <div className="text-[19px] w-[70px] font-medium leading-tight" style={{ overflow: 'hidden', textOverflow: 'ellipsis',  }}>{displayText}</div>}
          </div>

        {isOpen ? (
          <RiArrowUpSLine className="dropdown-icon-secondary text-customviolet" />
        ) : (
          <RiArrowDownSLine className="dropdown-icon-secondary text-customviolet" />
        )}
      </Menu.Button>
      <Menu.Items className="dropdown-menu text-[16px]">
        {dropdownOptions.categorie.map((category, index) => {
          return (
            <Menu.Item
              as="li"
              onClick={() => {setCategory(category);          console.log(categories)
              }}
              key={index}
              
              className="cursor-pointer hover:text-customviolet transition  "
            >
              {category}

             
            </Menu.Item>
          );
        })}
      </Menu.Items>
    </Menu>
  );
};

export default CategoryDropdown;
