import React from 'react';
import { Link } from 'react-router-dom';





export function FooterWithLogo() {
  return (<footer id="footer" class="relative bg-customviolet pt-8 pb-6">
    <div class="container mx-auto px-4">
      <div class="flex flex-wrap text-left lg:text-left">
        <div class="w-full lg:w-6/12 px-4">
          <h4 class="text-3xl fonat-semibold text-white">Let's keep in touch!</h4>
          <h5 class="text-lg mt-0 mb-2 text-white">
            Find us on any of these platforms
          </h5>
          <div class="mt-6 lg:mb-0 mb-6">
            <button class="bg-white text-lightBlue-400 shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2" type="button">
              <a target="_blank" href='https://www.linkedin.com/in/manpreet-singh-rajpal-1897152ba/?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app'>
                <i class="fab fa-linkedin-in"></i>

              </a>
            </button>
            <button class="bg-white text-blueGray-800 shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2" type="button">
              <a target="_blank" href="https://www.instagram.com/rajpalrealtors">
                <i class="fab fa-instagram"></i>
              </a>
            </button>
            <button class="bg-white  text-pink-400 shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2" type="button">
              <a target="_blank" href="https://www.youtube.com/@RajpalRealtors">
                <i class="fab fa-youtube"></i>
              </a>

            </button>
          </div>
        </div>
        <div class="w-full lg:w-6/12 px-4">
          <div class="flex flex-wrap items-top mb-6">
            <div class="w-full lg:w-4/12 px-4 ml-auto">
              <span class="block  text-white text-sm  mb-2">Useful Links</span>
              <ul class="list-unstyled">
                <li>
                  <Link class="text-white  block pb-2 text-sm" to="/aboutus">About Us</Link>
                </li>
                {/* <li>
                <a class="text-blueGray-600 hover:text-blueGray-800 font-semibold block pb-2 text-sm" href="https://blog.creative-tim.com?ref=njs-profile">Blog</a>
              </li>
              <li>
                <a class="text-blueGray-600 hover:text-blueGray-800 font-semibold block pb-2 text-sm" href="https://www.github.com/creativetimofficial?ref=njs-profile">Github</a>
              </li>
              <li>
                <a class="text-blueGray-600 hover:text-blueGray-800 font-semibold block pb-2 text-sm" href="https://www.creative-tim.com/bootstrap-themes/free?ref=njs-profile">Free Products</a> */}
                {/* </li> */}
              </ul>
            </div>
            <div class="w-full lg:w-4/12 px-4">
              <span class="block  text-white text-sm  mb-2">Contact Detail</span>
              <ul class="list-unstyled">
                <li>
                  <a class="text-white   block pb-2 text-sm" >Rajpalrealtorsdigital@gmail.com</a>
                </li>
                <li>
                  <a class="text-white   block pb-2 text-sm" >+91-7460999298</a>
                </li>
                <li>
                  <a class="text-white  block pb-2 text-sm" >Booth No.48, TDI Mohali-1 Commercial Center,
                    Sector 119, Mohali</a>
                </li>

              </ul>
            </div>
          </div>
        </div>
      </div>
      <hr class="my-6 border-blueGray-300" />
      <div class="flex flex-wrap items-center md:justify-between justify-center">
        <div class="w-full md:w-4/12 px-4 mx-auto text-center">
          <div class="text-sm text-white py-1">
            Copyright © <span id="get-current-year">2024</span><a href="https://www.creative-tim.com/product/notus-js" class="text-blueGray-500 hover:text-gray-800" target="_blank" /> Rajpal Realtors
            {/* <a href="https://www.creative-tim.com?ref=njs-profile" class="text-blueGray-500 hover:text-blueGray-800">Creative Tim</a>. */}
          </div>
        </div>
      </div>
    </div>
  </footer>
  );
}
