import React, { useContext } from 'react';

// import context
import { HouseContext } from './HouseContext';
// import components
import House from './House';
// import link
import { Link,useNavigate } from 'react-router-dom';
// import icons
import { ImSpinner2 } from 'react-icons/im';

const HouseList = () => {
  const { houses, loading } = useContext(HouseContext);
  const navigate = useNavigate();

  if (loading) {
    return (
      <ImSpinner2 className='mx-auto animate-spin text-violet-700 text-4xl mt-[200px]' />
    );
  }

  if (houses.length < 1) {
    return (
      <div className='text-center text-3xl text-gray-400 mt-48'>
        Sorry, nothing was found.
      </div>
    );
  };

  return (
    <section className='mb-20'>
      <div style={{ maxWidth: '1370px', margin: '0 auto' }} className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 lg:gap-14 mt-4 mb-4'>
      
        {houses.map((house, index) => {
          return (
            <Link key={index} to={`/property/${house.id}`} state={house}>
              <House house={house} />
            </Link>
          );
        })}
      </div>
    </section>
  );
};

export default HouseList;
