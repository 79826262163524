import React, { useState, useContext } from 'react';
import { RiMapPinLine, RiArrowDownSLine, RiArrowUpSLine } from 'react-icons/ri';
import { Menu } from '@headlessui/react';
import { HouseContext } from './HouseContext';
import { useLocation } from 'react-router-dom';

const CountryDropdown = () => {
  const { country, setCountry, countries,dropdownOptions} = useContext(HouseContext);const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();



  const displayText = country ? country : "City";

  const countryNameStyle = {
    overflow: 'hidden',
     textOverflow: 'ellipsis'
  };

  return (
    <Menu as="div" className="dropdown relative">
      <Menu.Button
        onClick={() => setIsOpen(!isOpen)}
        className="dropdown-btn w-full text-left"
      >
        <RiMapPinLine className="dropdown-icon-primary text-customviolet" />
        <div className=""> {/* Adjust the width as needed */}
        {location.pathname==="/allproperty"? <div className="text-[19px] font-medium leading-tight" style={countryNameStyle}>{displayText}</div> : <div className="text-[19px] w-[40px] font-medium leading-tight" style={countryNameStyle}>{displayText}</div>}
          </div>

        {isOpen ? (
          <RiArrowUpSLine className="dropdown-icon-secondary text-customviolet" />
        ) : (
          <RiArrowDownSLine className="dropdown-icon-secondary text-customviolet" />
        )}
      </Menu.Button>
      <Menu.Items className="dropdown-menu text-[16px]">
        {dropdownOptions.countrie.map((country, index) => {
          return (
            <Menu.Item
              as="li"
              onClick={() => setCountry(country)}
              key={index}
              className="cursor-pointer hover:text-customviolet transition"
            >
              <div style={countryNameStyle}>{country}</div>
            </Menu.Item>
          );
        })}
      </Menu.Items>
    </Menu>
  );
};

export default CountryDropdown;
