import React, { useState, useContext } from 'react';
// import icons
import {  RiArrowDownSLine, RiArrowUpSLine } from 'react-icons/ri';
import { FaFileContract } from "react-icons/fa";
// import headless ui components
import { Menu } from '@headlessui/react';
// import context
import { HouseContext } from './HouseContext';

const PropertyDropdown = () => {
  const { property, setProperty, properties ,dropdownOptions} = useContext(HouseContext);const [isOpen, setIsOpen] = useState(false);

  const countryNameStyle = {
    overflow: 'hidden',
     textOverflow: 'ellipsis'
  };
  const displayText = property ? property : "Type";

  return (
    <Menu as="div" className="dropdown relative">
      <Menu.Button
        onClick={() => setIsOpen(!isOpen)}
        className="dropdown-btn w-full text-left"
      >
        <FaFileContract className="dropdown-icon-primary text-customviolet" />
        <div className="truncate w-[60px]"> {/* Adjust the width as needed */}
          <div className="text-[19px] font-medium leading-tight" style={countryNameStyle}>{displayText}</div>
          </div>

        {isOpen ? (
          <RiArrowUpSLine className="dropdown-icon-secondary text-customviolet" />
        ) : (
          <RiArrowDownSLine className="dropdown-icon-secondary text-customviolet" />
        )}
      </Menu.Button>
      <Menu.Items className="dropdown-menu text-[16px]">
        {dropdownOptions.propertie.map((properties, index) => {
          return (
            <Menu.Item
              as="li"
              onClick={() => setProperty(properties)}
              key={index}
              className="cursor-pointer hover:text-customviolet transition"
            >
              {properties}
            </Menu.Item>
          );
        })}
      </Menu.Items>
    </Menu>
  );

};

export default PropertyDropdown;
