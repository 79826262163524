import React, { useEffect, useState } from "react";
import { collection, onSnapshot } from "firebase/firestore";
import { db } from "../firebase";
import { BiArea, BiBath, BiBed } from "react-icons/bi";
import Search from '../components/Search';
import HouseList from '../components/HouseList';

const PropertiesList = () => {
  const [properties, setProperties] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    const getProperties = onSnapshot(
      collection(db, "properties"),
      (snapshot) => {
        let list = [];
        snapshot.docs.forEach((doc) => {
          list.push({ id: doc.id, ...doc.data() });
        });
        setProperties(list);
        setLoading(false);
      },
      (error) => {
        console.log(error);
      }
    );

    return () => {
      getProperties();
    };
  }, []);

  console.log(properties);

  if (properties.length < 1) {
    return (
      <div className="text-center text-3xl text-gray-400">
        Sorry, nothing was found.
      </div>
    );
  };

  return (
    <><div className="mb-6"></div>
        <Search/>
    
      <HouseList />
    </>
  );
};

export default PropertiesList;
