import React from 'react';

import { Routes, Route } from 'react-router-dom';
import { FooterWithLogo} from './components/Footer';
import Header from './components/Header';
import Favourites from './pages/Favourites';
import AboutUs from './pages/aboutus';
// import pages
import Home from './pages/Home';
import PropertyDetails from './pages/PropertyDetails';

const App = () => {
  return (
    <div className='w-full mx-auto bg-white'>
      <Header />
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/allproperty' element={<Favourites/>} />
        <Route path='/property/:id' element={<PropertyDetails />} />
        <Route path='/aboutus' element={<AboutUs />} />
      </Routes>
      <FooterWithLogo/>
    </div>
  );
};

export default App;