import React, { createContext, useState, useEffect } from "react";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../firebase";

// import data

// create context
export const HouseContext = createContext();

// provider
const HouseContextProvider = ({ children }) => {
  // const [houses, setHouses] = useState(housesData);
  const [houses, setHouses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [country, setCountry] = useState();
  const [countries, setCountries] = useState([]);
  const [property, setProperty] = useState();
  const [properties, setProperties] = useState([]);
  const [category, setCategory] = useState();
  const [categories, setCategories] = useState([]);
  const [price, setPrice] = useState();
  const[Options,setOptions] = useState([]);
  // HouseContext.js

// Define a state for all dropdown options
const [dropdownOptions, setDropdownOptions] = useState({
  countrie: [],
  propertie: [],
  categorie: [],
 });


const fetchProperties = async () => {
  const propertiesRef = collection(db, "properties");
  const snapshot = await getDocs(propertiesRef);
  const properties = snapshot.docs.map(doc => ({
     id: doc.id,
     ...doc.data(),
     img: doc.data().img || 'default-image-url', // Replace 'default-image-url' with a default image URL if needed
  }));
  setHouses(properties);
  setOptions(properties);
  setLoading(false);
  console.log(properties);
 };

 useEffect(() => {
    fetchProperties();
 }, []);

  useEffect(() => {
    // return all countries
    const allCountries = houses.map((house) => {
      return house.country;
    });

    // remove duplicates
    const uniqueCountries = ["any", ...new Set(allCountries)];
  console.log(uniqueCountries)
    // set countries state
  
  }, [houses]);
  useEffect(() => {
    const allCountrie = Options.map((house) => {
      return house.country;
    });
    const allPropertie = Options.map((house) => {
      return house.type;
    });
    const allCategorie = Options.map((house) => {
      return house.category;
    });
    const uniqueCountrie = ["any", ...new Set(allCountrie)];
    const uniquePropertie = ["any", ...new Set(allPropertie)];
    const uniqueCategorie = ["any", ...new Set(allCategorie)];
    setDropdownOptions({
      countrie: uniqueCountrie,
      propertie: uniquePropertie,
      categorie: uniqueCategorie
    });
  
  },[Options]);
  useEffect(() => {
    // return only countries
    const allProperties = houses.map((house) => {
      return house.type;
    });

    // remove duplicates
    const uniqueProperties = ["any", ...new Set(allProperties)];
 
    // set countries state
    setProperties(uniqueProperties);
  }, [houses]);

  useEffect(() => {
    // Recalculate unique countries, properties, and categories based on the filtered houses
   
   
    const allCategories = houses.map(house => house.category);
    const uniqueCategories = ["any", ...new Set(allCategories)];

    setCategories(uniqueCategories);
   }, [houses]); // Depend on the houses state to recalculate when it changes

  const handleClick = () => {
    setLoading(true);
   
    const newHouses = houses.filter((house) => {
       // Check for default values and combinations without price
       const isDefault = (str) => {
         return str.split(" ").includes("any");
       };
   
       // All values are selected
       if (!isDefault(category) && !isDefault(property) && !isDefault(country)) {
         return house.category === category && house.country === country && house.type === property;
       }
       // All values are default
       if (isDefault(country) && isDefault(property) && isDefault(category)) {
         return house;
       }
       // Single pair combinations without price
       if (!isDefault(category) && isDefault(country) && isDefault(property)) {
         return house.category === category;
       }
       if (!isDefault(country) && isDefault(property) && isDefault(category)) {
         return house.country === country;
       }
       if (!isDefault(property) && isDefault(country) && isDefault(category)) {
         return house.type === property;
       }
       // Two pairs combinations without price
       if (!isDefault(country) && !isDefault(property) && isDefault(category)) {
         return house.country === country && house.type === property;
       }
       if (!isDefault(category) && !isDefault(property) && isDefault(country)) {
         return house.category === category && house.country === country;
       }
       if (!isDefault(category) && !isDefault(country) && isDefault(property)) {
         return house.category === category && house.type === property;
       }
       // No conditions left that involve price
   
       return false; // Return false if none of the conditions match
    });
   
    setTimeout(() => {
       return (
         newHouses.length < 1 ? setHouses([]) : setHouses(newHouses),
         setLoading(false)
       );
    }, 1000);
   };

  // const handleClick = () => {
  //   setLoading(true);
  //   // check the string if includes '(any)'
  //   const isDefault = (str) => {
  //     return str.split(" ").includes("(any)");
  //   };

  //   // get first string (price) and parse it to number
  //   const minPrice = parseInt(price.split(" ")[0]);
  //   console.log(minPrice);
  //   // get last string (price) and parse it to number
  //   const maxPrice = parseInt(price.split(" ")[2]);
  //   console.log(maxPrice);

  //   const newHouses = housesData.filter((house) => {
  //     const housePrice = parseInt(house.price);

  //     //4 pairs combinations

  //     // all values are selected
  //     if (!isDefault(category) && !isDefault(property) && !isDefault(country) && !isDefault(price)) {
  //       if (housePrice >= minPrice && housePrice <= maxPrice) {
  //         return  house.category === category && house.country === country && house.type === property;
  //       }
  //     }
  //     // all values are default
  //     if (
  //       isDefault(country) &&
  //       isDefault(property) &&
  //       isDefault(price) &&
  //       isDefault(category)
  //     ) {
  //       return house;
  //     }

  //     //single(1) pair combinations

  //     // category is not default (category is selected)
  //     if (
  //       !isDefault(category) &&
  //       isDefault(country) &&
  //       isDefault(property) &&
  //       isDefault(price)
  //     ) {
  //       return house.category === category;
  //     }
  //     // country is not default (country is selected)
  //     if (!isDefault(country) && isDefault(property) && isDefault(price) && isDefault(category)) {
  //       return house.country === country;
  //     }
  //     // property is not default (property is selected)
  //     if (!isDefault(property) && isDefault(country) && isDefault(price) && isDefault(category)) {
  //       return house.type === property;
  //     }
  //     // price is not default (price is selected)
  //     if (!isDefault(price) && isDefault(country) && isDefault(property) && isDefault(category)) {
  //       if (housePrice >= minPrice && housePrice <= maxPrice) {
  //         return house;
  //       }
  //     }

  //     //3 pairs combinations

  //      //only price is default (country, property and category is selected)
  //      if (isDefault(price) && !isDefault(country) && !isDefault(property) && !isDefault(category)) {
  //       return house.country === country && house.type === property && house.category === category;
  //     }
  //      //only country is default (category, property and price is selected)
  //      if (!isDefault(price) && isDefault(country) && !isDefault(property) && !isDefault(category)) {
  //       if (housePrice >= minPrice && housePrice <= maxPrice) {
  //         return house.type === property && house.category === category;
  //       }
  //     }
  //      //only property is default (country, category and price is selected)
  //      if (!isDefault(price) && !isDefault(country) && isDefault(property) && !isDefault(category)) {
  //       if (housePrice >= minPrice && housePrice <= maxPrice) {
  //         return house.country === country && house.category === category;
  //       }
  //     }
  //      //only category is default (country, property and price is selected)
  //      if (!isDefault(price) && !isDefault(country) && !isDefault(property) && isDefault(category)) {
  //       if (housePrice >= minPrice && housePrice <= maxPrice) {
  //         return house.type === property && house.country === country;
  //       }
  //     }

  //      //2 pairs combinations

  //      // country and property is not default (country and property is selected)
  //      if (
  //       !isDefault(country) &&
  //       !isDefault(property) &&
  //       isDefault(price) &&
  //       isDefault(category)
  //     ) {
  //       return house.country === country && house.type === property;
  //     }
  //     // category and property is not default (category and property is selected)
  //     if (
  //       !isDefault(category) &&
  //       !isDefault(property) &&
  //       isDefault(price) &&
  //       isDefault(country)
  //     ) {
  //       return house.category === category && house.type === property;
  //     }
  //     // category and country is not default (category and country is selected)
  //     if (
  //       !isDefault(category) &&
  //       !isDefault(country) &&
  //       isDefault(price) &&
  //       isDefault(property)
  //     ) {
  //       return house.country === country && house.category === category;
  //     }

  //      // category and price is not default (category and price is selected)
  //      if (
  //       !isDefault(category) &&
  //       !isDefault(price) &&
  //       isDefault(country) &&
  //       isDefault(property)
  //     ) {
  //         if (housePrice >= minPrice && housePrice <= maxPrice) {
  //           return house.category === category;
  //         }
  //     }
  //     // country and price is not default (country and price is selected)
  //     if (
  //       !isDefault(country) &&
  //       isDefault(property) &&
  //       isDefault(category) &&
  //       !isDefault(price)
  //     ) {
  //       if (housePrice >= minPrice && housePrice <= maxPrice) {
  //         return house.country === country;
  //       }
  //     }
  //     // property and price is not default (property and price is selected)
  //     if (
  //       isDefault(country) &&
  //       !isDefault(property) &&
  //       !isDefault(price) &&
  //       isDefault(category)
  //     ) {
  //       if (housePrice >= minPrice && housePrice <= maxPrice) {
  //         return house.type === property;
  //       }
  //     }
  //   });

  //   setTimeout(() => {
  //     return (
  //       newHouses.length < 1 ? setHouses([]) : setHouses(newHouses),
  //       setLoading(false)
  //     );
  //   }, 1000);
  // };
  const resetFilters = async () => {
    setLoading(true);
    await fetchProperties(); // Assuming fetchProperties fetches all properties
    setLoading(false);
   };
  return (
    <HouseContext.Provider
      value={{
        country,
        setCountry,
        countries,
        property,
        setProperty,
        properties,
        price,
        setPrice,
        handleClick,
        houses,
        loading,
        category,
        setCategory,
        dropdownOptions,
        categories,
      }}
    >
      {children}
    </HouseContext.Provider>
  );
};

export default HouseContextProvider;
