import React, { useEffect, useState } from 'react';

import { BiBed, BiBath, BiArea} from 'react-icons/bi';
import { useLocation } from 'react-router-dom';
import { collection, addDoc, serverTimestamp } from "firebase/firestore";
import { db } from "../firebase";
import Swal from "sweetalert2";



const PropertyDetails = () => {
  const location = useLocation();
  const property = location.state; // Use optional chaining to prevent errors

 const handleMessageSubmit = async (e) => {
  e.preventDefault();

  if (!property) {
    console.error("Property is undefined");
    return;
  }

  const name = e.target.elements.name.value;
  const email = e.target.elements.email.value;
  const phone = e.target.elements.phone.value;
  const message = e.target.elements.message.value;

  const newMessage = {
    name,
    email,
    phone,
    message,
    timestamp: serverTimestamp(),
    propertyId: property.id,
  };

  try {
    await addDoc(collection(db, "messages"), newMessage);
          // Show a popup to the user
          Swal.fire({
            title: "Success",
            text: "Your message has been sent!",
            icon: "success"
          });
          // Clear the form
          e.target.reset();
  } catch (error) {
    console.error("Error adding message: ", error);
    // Optionally, show an error message to the user
    alert('An error occurred while sending your message. Please try again.');  }
};

  if (!property) {
     return <div>Property not found</div>;
  }

  return (
    <div className='container mx-auto min-h-[800px] mb-14'>
      <div className='flex flex-col lg:flex-row lg:items-center lg:justify-between'>
        <div>
          <h2 className='text-2xl font-semibold'>{property.name}</h2>
          <h3 className='text-lg mb-4'>{property.address}</h3>
        </div>
        <div className='mb-4 lg:mb-0 flex gap-x-2 text-sm'>
        
        </div>
        {property.price !== "0" && (
          <div className='text-3xl font-semibold text-customviolet_light'>
            Rs. {property.price} onwards
          </div>
        )}
       </div>
       <div className='flex flex-col items-center gap-8 lg:flex-row'>
        <div className='max-w-[768px]'>
          <div className='mb-8'>
            <img src={property.img} alt='property' />
          </div>
          <div className='flex gap-x-6 text-customviolet mb-6'>
            {property.bedrooms!=='' && (
               <div className='flex gap-x-2 items-center'>
               <BiBed className='text-2xl' />
               <div className='text-lg font-medium'>{property.bedrooms}</div>
             </div>
            )}
           
            {property.bathrooms!=='' &&(
            <div className='flex gap-x-2 items-center'>
            <BiBath className='text-2xl' />
            <div className='text-lg font-medium'>{property.bathrooms}</div>
          </div>)}
            
            <div className='flex gap-x-2 items-center'>
              <BiArea className='text-2xl' />
              <div className='text-lg font-medium'>{property.surface}</div>
            </div>
          </div>
          {/* <p>{property.description}</p> */}
          <div className='bg-green-500 rounded-full text-white px-3 inline-block mr-2'>
            {property.type}
          </div>
          <div className='bg-customviolet_light rounded-full text-white px-3 inline-block'>
            {property.country}
          </div>
          <div>
          <div className='mb-5'></div>
          </div>
          <h2 className='text-2xl font-normal'>{property.description}</h2>
        </div>
        <div className='flex-1 w-full mb-8 bg-white border border-gray-300 rounded-lg px-6 py-8'>
        <h3 className='text-2xl font-semibold mb-4'>
          <span className='text-customviolet'>Ask</span> your
          <span className='text-customviolet'> Queries</span> Here
        </h3>
        <form className='flex flex-col gap-y-4' onSubmit={handleMessageSubmit}>
      <input
        className='border border-gray-300 focus:border-violet-700 rounded w-full px-4 h-14 text-sm outline-none'
        type='text'
        name='name' // Make sure this name attribute matches the one used in the handler
        placeholder='Name*'
        required
      />
      <input
        className='border border-gray-300 focus:border-violet-700 rounded w-full px-4 h-14 text-sm outline-none'
        type='email'
        name='email' // Make sure this name attribute matches the one used in the handler
        placeholder='Email*'
        required
      />
      <input
        className='border border-gray-300 focus:border-violet-700 rounded w-full px-4 h-14 text-sm outline-none'
        type='tel'
        name='phone' // Make sure this name attribute matches the one used in the handler
        placeholder='Phone*'
        required
      />
      <textarea
        className='border border-gray-300 focus:border-violet-700 rounded w-full p-4 h-36 text-sm text-gray-400 outline-none resize-none'
        name='message' // Make sure this name attribute matches the one used in the handler
        placeholder='Message*'
        defaultValue='Hello, I am interested in [Modern apartment]'
        required
      />
      <div className='flex gap-x-2'>
        <button
          className='bg-customviolet hover:bg-customviolet_light text-white rounded p-4 text-sm w-full transition'
          type='submit'
        >
          Send message
        </button>
        {/* <button className='border border-violet-700 text-violet-700 hover:border-purple-600 hover:text-purple-600 rounded p-4 text-sm w-full transition'>
          Call
        </button> */}
      </div>
    </form>
        </div>
      </div>
    </div>
  );
};

export default PropertyDetails;
